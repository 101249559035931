import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

export const getAllCustomerAsync = createAsyncThunk(
  "master/getAllCustomer",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/lead/list-customer?page=${payload?.page || 1}&limit=${payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

//getByID Customer Location
export const getAllLocationsByIdAsync = createAsyncThunk(
  "master/getAllLocationsByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/lead/get-location-details/${payload}`,
      [],
      toolkit
    );
  }
);

// delete Customer
export const deleteCustomerAsync = createAsyncThunk(
  "master/deleteCustomer",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/lead/delete-customer/${payload}`,
      [],
      toolkit
    );
  }
);

// Get All Customer Without Pagination
export const getAllCustomerWithoutPaginationAsync = createAsyncThunk(
  "master/getAllCustomerWithoutPagination",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/lead/list-customer`, [], toolkit);
  }
);

// Get Customers without pagination and search filter
export const getCustomerWithoutPaginationAsync = createAsyncThunk(
  "master/getCustomerWithoutPagination",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/lead/list-customer`, [], toolkit);
  }
);

//download Customers Csv File
export const customerExcelDownloadAsync = ({page, limit, search}) => {

  window.open(
    `${process.env.REACT_APP_BASE_URL}/lead/customer-export-to-csv?token=${isJson(localStorage.getItem("auth"))
      ? JSON.parse(localStorage.getItem("auth"))?.token
      : null
    }&page=${page || 1}&limit=${limit || 10
    }&search=${search || ''}`,
    "_parent"
  );
};