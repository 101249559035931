import SvgColor from 'components/svg-color/SvgColor';
import { PATH_DASHBOARD } from 'routes/paths';

export const PATH_ROUTE = {
  dashboard: PATH_DASHBOARD.app,
  users: '/app/user-management',
  usermanagement: PATH_DASHBOARD.management,
  permission: PATH_DASHBOARD.permission,
  user: PATH_DASHBOARD.user,
  master: '/app/master',
  country: PATH_DASHBOARD.country,
  accreditation: PATH_DASHBOARD.accreditation,
  credential: PATH_DASHBOARD.credential,
  state: PATH_DASHBOARD.state,
  portlist: PATH_DASHBOARD.portlist,
  city: PATH_DASHBOARD.city,
  business: PATH_DASHBOARD.business,
  measurement: PATH_DASHBOARD.measurement,
  vendor: PATH_DASHBOARD.vendor,
  service: PATH_DASHBOARD.service,
  sites: PATH_DASHBOARD.sites,
  vessels: PATH_DASHBOARD.vessels,
  activities: PATH_DASHBOARD.activities,
  vendorsku: PATH_DASHBOARD.vendorsku,
  inventory: PATH_DASHBOARD.inventory,
  document: PATH_DASHBOARD.document,
  leadsource: PATH_DASHBOARD.leadsource,
  pincode: PATH_DASHBOARD.pincode,
  currency: PATH_DASHBOARD.currency,
  pest: PATH_DASHBOARD.pest,
  invoice: '/app/invoice',
  invoices: PATH_DASHBOARD.invoices,
  receipt: PATH_DASHBOARD.receipt,
  lead: '/app/lead',
  lead: PATH_DASHBOARD.lead,
  customers: '/app/customers',
  customers: PATH_DASHBOARD.customers,
  contract: '/app/contract',
  contract: PATH_DASHBOARD.contract,
  inbound: '/app/inbound',
  inbound: PATH_DASHBOARD.inbound,
  sales: '/app/sales',
  sales: PATH_DASHBOARD.sales,
  'Purchase Menu': '/app/purchasemenu',
  purchase: PATH_DASHBOARD.purchase,
  quotation: '/app/quotation',
  quotation: PATH_DASHBOARD.quotation,
  roster: '/app/roster',
  roster: PATH_DASHBOARD.roster,
  feedback: '/app/feedback',
  feedback: PATH_DASHBOARD.feedback,
  expense: '/app/expense',
  expense: PATH_DASHBOARD.expense,
  attendance: '/app/attendance',
  attendance: PATH_DASHBOARD.attendance,
  services: '/app/services',
  mainservice: PATH_DASHBOARD.mainservice,
  jobs: PATH_DASHBOARD.jobs,
  certificates: PATH_DASHBOARD.certificates,
  operations: '/app/operations',
  inventoryallocations: PATH_DASHBOARD.inventoryallocations,
  documents: '/app/governmentdocument',
  appendix: PATH_DASHBOARD.appendix,
  MIS: '/app/mis',
  leadconversion: PATH_DASHBOARD.leadconversion,
  quotationconversion: PATH_DASHBOARD.quotationconversion,
  contractconversion: PATH_DASHBOARD.contractconversion,
  revenuenumbers: PATH_DASHBOARD.revenuenumbers,
  profitability: PATH_DASHBOARD.profitability,
  collectionpercentage: PATH_DASHBOARD.collectionpercentage,
  totalCustomerCount: PATH_DASHBOARD.totalCustomerCount,
  manPowerUtilization: PATH_DASHBOARD.manPowerUtilization,
  customersrevenue: PATH_DASHBOARD.customersrevenue,
  customerspayables: PATH_DASHBOARD.customerspayables,
  customersdelaying: PATH_DASHBOARD.customersdelaying,
  gstreports: PATH_DASHBOARD.gstreports,
  outstandingreport: PATH_DASHBOARD.outstandingreports,
  containerreports: PATH_DASHBOARD.containerreports,
};

export const IconsGenerate = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/dynamic/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);
