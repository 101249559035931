import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

export const getRevenueNumberChartDataAsync = createAsyncThunk(
  "mis/getRevenueNumberChartData",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/mis/get-revenue-chart-data`,
      payload,
      toolkit
    );
  }
);
export const getTopXCustomerRevenueAsync = createAsyncThunk(
  "mis/getTopXCustomerRevenue",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/mis/get-top-customer-revenue`,
      payload,
      toolkit
    );
  }
);
export const getTopXCustomerPayablesAsync = createAsyncThunk(
  "mis/getTopXCustomerPayables",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/mis/get-top-customer-payables`,
      payload,
      toolkit
    );
  }
);
export const getTopXCustomerDelayingAsync = createAsyncThunk(
  "mis/getTopXCustomerDelaying",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/mis/get-top-customers-delaying-payments`,
      payload,
      toolkit
    );
  }
);
//  For gst reports
export const getGstReportsAsync = createAsyncThunk(
  "mis/getGstReportsAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/mis/get-gst-report?customer=${payload?.customer || ""}&fromDate=${
        payload?.fromDate || ""
      }&toDate=${payload?.toDate || ""}&site=${payload?.site || ""}`,
      payload,
      toolkit
    );
  }
);

// For Outstanding report
export const getOutStandingReportAsync = createAsyncThunk(
  "mis/getOutStandingReportAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/mis/get-outstanding-report?customer=${
        payload?.customer || ""
      }&fromDate=${payload?.fromDate || ""}&toDate=${
        payload?.toDate || ""
      }&site=${payload?.site || ""}`,
      payload,
      toolkit
    );
  }
);
export const getContainerReportAsync = createAsyncThunk(
  "mis/getContainerReportAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/mis/get-container-report?customer=${payload?.customer || ""}&fromDate=${
        payload?.fromDate || ""
      }&toDate=${payload?.toDate || ""}&site=${payload?.site || ""}`,
      payload,
      toolkit
    );
  }
);


export const outStandingReportExcelDownloadAsync = (payload) => {
    window.open(`${process.env.REACT_APP_BASE_URL}/mis/download-outstanding-report?customer=${payload?.customer || ""}&fromDate=${payload?.fromDate || ""}&toDate=${payload?.toDate || ""}&site=${payload?.site || ""}`, "_parent");
  };

// // //download out standing report Csv File
// // export const  = ({

// // }) => {
//   window.open(
//     `${
//       process.env.REACT_APP_BASE_URL
//     }/mis/download-outstanding-report?customer=${customer || ""}&fromDate=${
//       fromDate || ""
//     }&toDate=${toDate || ""}&site=${site || ""}`,
   
//   );
// };
