import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllQuotationAsync,
  createQuotationAsync,
  getQuotationByIdAsync,
  getQuotationModalServices,
  updateQuotationAsync,
  deleteQuotationAsync,
  updateQuotationStatusAsync,
  getQuotationListDropDownAsync,
  getQuotationListContactLocationServiceAsync,
  getListChemicalDropDownAsync,
  getQuotationDocumentDropdownAsync,
  getDocumentHtmlByDocumentIdAsync,
  quotationSavePdfAsync,
  getAllQuotationWithOutPaginationAsync,
  quotationEmailPdfAsync
} from "./quotation.async";

const initialState = {
  quotationCreateLoader: false,
  quotationCreateInfo: {},

  quotationListDropDownLoader: false,
  quotationListDropDown: [],
  quotationListDropDownInfo: {},

  quotationListContactLocationServiceLoader: false,
  quotationListContactLocationServiceList: [],

  quotationDropDownChemicalListLoader: false,
  quotationDropDownChemicalList: [],

  quotationListLoader: false,
  quotationList: [],
  quotationListInfo: {},

  quotationByIdLoading: false,
  quotationById: {},
  quotationByIdInfo: {},

  quotationModalByIdLoading: false,
  quotationModalById: {},
  quotationModalByIdInfo: {},

  quotationUpdateLoader: false,
  quotationUpdateInfo: {},

  quotationDeleteLoading: false,
  quotationDeleteInfo: {},

  updateQuotationStatusLoader: false,
  updateQuotationStatusInfo: {},

  quotationDocumentDropDownLoader: false,
  quotationDocumentDropDown: [],

  documentHtmlByDocumentIdLoading: false,
  documentHtmlByDocumentId: {},
  documentHtmlByDocumentIdInfo: {},

  quotationSavePdfLoader: false,
  quotationSavePdfInfo: {},

  quotationListWithoutPaginationLoader: false,
  quotationListWithoutPagination: [],
  quotationListWithoutPaginationInfo: {},

  quotationEmailPdfLoader: false,
  quotationEmailPdfInfo: {}
};

export const quotationSlice = createSlice({
  name: "quotation",
  initialState,
  extraReducers: (builder) => {
    //Quotation Email Pdf
    builder.addMatcher(isAnyOf(quotationEmailPdfAsync.pending), (state) => {
      state.quotationEmailPdfLoader = true;
    });
    builder.addMatcher(
      isAnyOf(quotationEmailPdfAsync.fulfilled),
      (state, action) => {
        state.quotationEmailPdfLoader = false;
        state.quotationEmailPdfInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(quotationEmailPdfAsync.rejected),
      (state, action) => {
        state.quotationEmailPdfLoader = false;
      }
    );

    //Quotation Save Pdf

    builder.addMatcher(isAnyOf(quotationSavePdfAsync.pending), (state) => {
      state.quotationSavePdfLoader = true;
    });
    builder.addMatcher(
      isAnyOf(quotationSavePdfAsync.fulfilled),
      (state, action) => {
        state.quotationSavePdfLoader = false;
        state.quotationSavePdfInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(quotationSavePdfAsync.rejected),
      (state, action) => {
        state.quotationSavePdfLoader = false;
      }
    );

    //Document Html By Document Id
    builder.addMatcher(
      isAnyOf(getDocumentHtmlByDocumentIdAsync.pending),
      (state) => {
        state.documentHtmlByDocumentIdLoading = true;
        state.documentHtmlByDocumentIdInfo = {};
        state.documentHtmlByDocumentId = {};
      }
    );
    builder.addMatcher(
      isAnyOf(getDocumentHtmlByDocumentIdAsync.fulfilled),
      (state, action) => {
        state.documentHtmlByDocumentIdLoading = false;
        state.documentHtmlByDocumentIdInfo = {};
        state.documentHtmlByDocumentId = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getDocumentHtmlByDocumentIdAsync.rejected),
      (state, action) => {
        state.documentHtmlByDocumentIdLoading = false;
        state.documentHtmlByDocumentIdInfo = {};
        state.documentHtmlByDocumentId = {};
      }
    );

    // Quotation document Drop Down List

    builder.addMatcher(
      isAnyOf(getQuotationDocumentDropdownAsync.pending),
      (state) => {
        state.quotationDocumentDropDownLoader = true;
        state.quotationDocumentDropDown = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getQuotationDocumentDropdownAsync.fulfilled),
      (state, action) => {
        state.quotationDocumentDropDownLoader = false;
        state.quotationDocumentDropDown = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getQuotationDocumentDropdownAsync.rejected),
      (state, action) => {
        state.quotationDocumentDropDownLoader = false;
        state.quotationDocumentDropDown = [];
      }
    );

    // Quotation Create

    builder.addMatcher(isAnyOf(createQuotationAsync.pending), (state) => {
      state.quotationCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createQuotationAsync.fulfilled),
      (state, action) => {
        state.quotationCreateLoader = false;
        state.quotationCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createQuotationAsync.rejected),
      (state, action) => {
        state.quotationCreateLoader = false;
      }
    );

    //Quotation By ID

    builder.addMatcher(isAnyOf(getQuotationByIdAsync.pending), (state) => {
      state.quotationByIdLoading = true;
      state.quotationByIdInfo = {};
      state.quotationById = {};
    });
    builder.addMatcher(
      isAnyOf(getQuotationByIdAsync.fulfilled),
      (state, action) => {
        state.quotationByIdLoading = false;
        state.quotationByIdInfo = {};
        state.quotationById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getQuotationByIdAsync.rejected),
      (state, action) => {
        state.quotationByIdLoading = false;
        state.quotationByIdInfo = {};
        state.quotationById = {};
      }
    );
    //QuotationModal By ID

    builder.addMatcher(isAnyOf(getQuotationModalServices.pending), (state) => {
      state.quotationModalByIdLoading = true;
      state.quotationModalByIdInfo = {};
      state.quotationModalById = {};
    });
    builder.addMatcher(
      isAnyOf(getQuotationModalServices.fulfilled),
      (state, action) => {
        state.quotationModalByIdLoading = false;
        state.quotationModalByIdInfo = [];
        state.quotationModalById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getQuotationModalServices.rejected),
      (state, action) => {
        state.quotationModalByIdLoading = false;
        state.quotationModalByIdInfo = {};
        state.quotationModalById = [];
      }
    );

    //QuotationListContactLocationServiceList

    builder.addMatcher(
      isAnyOf(getQuotationListContactLocationServiceAsync.pending),
      (state) => {
        state.getQuotationListContactLocationServiceAsyncLoader = true;
        // state.quotationByIdInfo = {};
        state.quotationListContactLocationServiceList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getQuotationListContactLocationServiceAsync.fulfilled),
      (state, action) => {
        state.getQuotationListContactLocationServiceAsyncLoader = false;
        // state.quotationByIdInfo = {};
        state.quotationListContactLocationServiceList = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getQuotationListContactLocationServiceAsync.rejected),
      (state, action) => {
        state.getQuotationListContactLocationServiceAsyncLoader = false;
        // state.quotationByIdInfo = {};
        state.quotationListContactLocationServiceList = [];
      }
    );

    //getListChemicalDropDownAsync

    builder.addMatcher(
      isAnyOf(getListChemicalDropDownAsync.pending),
      (state) => {
        state.quotationDropDownChemicalListLoader = true;
        // state.quotationByIdInfo = {};
        state.quotationDropDownChemicalList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getListChemicalDropDownAsync.fulfilled),
      (state, action) => {
        state.quotationDropDownChemicalListLoader = false;
        // state.quotationByIdInfo = {};
        state.quotationDropDownChemicalList = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getListChemicalDropDownAsync.rejected),
      (state, action) => {
        state.quotationDropDownChemicalListLoader = false;
        // state.quotationByIdInfo = {};
        state.quotationDropDownChemicalList = [];
      }
    );

    // Quotation List

    builder.addMatcher(isAnyOf(getAllQuotationAsync.pending), (state) => {
      state.quotationListLoader = true;
      state.quotationListInfo = {};
      state.quotationList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllQuotationAsync.fulfilled),
      (state, action) => {
        state.quotationListLoader = false;
        state.quotationListInfo = {};
        state.quotationList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllQuotationAsync.rejected),
      (state, action) => {
        state.quotationListLoader = false;
        state.quotationListInfo = {};
        state.quotationList = [];
      }
    );

    // Quotation Drop Down List

    builder.addMatcher(
      isAnyOf(getQuotationListDropDownAsync.pending),
      (state) => {
        state.quotationListDropDownLoader = true;
        state.quotationListDropDown = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getQuotationListDropDownAsync.fulfilled),
      (state, action) => {
        state.quotationListDropDownLoader = false;
        state.quotationListDropDown = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getQuotationListDropDownAsync.rejected),
      (state, action) => {
        state.quotationListDropDownLoader = false;
        state.quotationListDropDown = [];
      }
    );

    //Quotation Update

    builder.addMatcher(isAnyOf(updateQuotationAsync.pending), (state) => {
      state.quotationUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateQuotationAsync.fulfilled),
      (state, action) => {
        state.quotationUpdateLoader = false;
        state.quotationUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateQuotationAsync.rejected),
      (state, action) => {
        state.quotationUpdateLoader = false;
      }
    );

    //Quotation Delete

    builder.addMatcher(isAnyOf(deleteQuotationAsync.pending), (state) => {
      state.quotationDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteQuotationAsync.fulfilled),
      (state, action) => {
        state.quotationDeleteLoading = false;
        state.quotationDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteQuotationAsync.rejected),
      (state, action) => {
        state.quotationDeleteLoading = false;
      }
    );

    //Quotation Status Update

    builder.addMatcher(isAnyOf(updateQuotationStatusAsync.pending), (state) => {
      state.updateQuotationStatusLoader = true;
      state.quotationListLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateQuotationStatusAsync.fulfilled),
      (state, action) => {
        state.updateQuotationStatusLoader = false;
        state.quotationListLoader = false;

        state.updateQuotationStatusInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateQuotationStatusAsync.rejected),
      (state, action) => {
        state.updateQuotationStatusLoader = false;
        state.quotationListLoader = false;
      }
    );

    // Quotation List Without Pagination

    builder.addMatcher(
      isAnyOf(getAllQuotationWithOutPaginationAsync.pending),
      (state) => {
        state.quotationListWithoutPaginationLoader = true;
        state.quotationListWithoutPaginationInfo = {};
        state.quotationListWithoutPagination = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllQuotationWithOutPaginationAsync.fulfilled),
      (state, action) => {
        state.quotationListWithoutPaginationLoader = false;
        state.quotationListWithoutPaginationInfo = {};
        state.quotationListWithoutPagination = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllQuotationWithOutPaginationAsync.rejected),
      (state, action) => {
        state.quotationListWithoutPaginationLoader = false;
        state.quotationListWithoutPaginationInfo = {};
        state.quotationListWithoutPagination = [];
      }
    );
  },
  reducers: {
    emptyQuotation: () => initialState
  }
});
export const { emptyQuotation } = quotationSlice.actions;

export default quotationSlice.reducer;
