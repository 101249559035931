import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

export const getAllLeadAsync = createAsyncThunk(
  "master/getAllLeadAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/lead/list-lead?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}&businessId=${payload.customer || ""}`,
      [],
      toolkit
    );
  }
);
export const getSuggestionAsync = createAsyncThunk(
  "master/getSuggestionAsync",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/lead/list-lead?search=${payload.search || ""}`, [], toolkit);
  }
);

// get chamical

export const getChemicalDropdownAsync = createAsyncThunk(
  "master/getAlllead",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/contract/list-chemical-dropdown/${payload?.serviceid}`,
      [],
      toolkit
    );
  }
);

//  get all Laed without pagination
export const getLeadWithoutPaginationAsync = createAsyncThunk(
  "contract/getAlllead",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/contract/list-lead-dropdown`,
      [],
      toolkit
    );
  }
);

// Qutation Dropdown

export const getQuotationDropdownAsync = createAsyncThunk(
  "contract/getQuotationDropdown",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/contract/list-quotation-dropdown/${payload?.leadid}`,
      [],
      toolkit
    );
  }
);

// service dropdown
export const getServiceDropdownAsync = createAsyncThunk(
  "contract/getServiceDropdown",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/contract/list-services-dropdown/${payload?.quotationid}`,
      [],
      toolkit
    );
  }
);

export const createLeadAsync = createAsyncThunk(
  "master/createLeadAsync",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/lead/create-lead`, payload, toolkit);
  }
);

export const updateLeadAsync = createAsyncThunk(
  "admin/updateLeadAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/lead/update-lead/${payload.id}`,
      payload,
      toolkit
    );
  }
);

export const getLeadByIdAsync = createAsyncThunk(
  "master/getLeadByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/lead/get-lead/${payload}`, [], toolkit);
  }
);

export const deleteLeadAsync = createAsyncThunk(
  "master/deleteLeadAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/lead/delete-lead/${payload}`,
      [],
      toolkit
    );
  }
);

// Update lead status

export const updateLeadStatusAsync = createAsyncThunk(
  "admin/updateLeadStatus",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/lead/update-lead-status/${payload.id}`,
      payload,
      toolkit
    );
  }
);

//download Lead Csv File
export const leadExcelDownloadAsync = ({ page, limit, search, customer}) => {
  window.open(
    `${process.env.REACT_APP_BASE_URL}/lead/lead-export-to-csv?token=${
      isJson(localStorage.getItem("auth"))
        ? JSON.parse(localStorage.getItem("auth"))?.token
        : null
    }&page=${page || 1}&limit=${
      limit || 10
    }&search=${search || ""}&businessName=${customer || ""}`,
    "_parent"
  );
};

// filter leads  lead/check-parameters?gst

export const getLeadCheckParametersAsync = createAsyncThunk(
  "lead/getLeadCheckParameters",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/lead/check-parameters/${payload?.leadid}`,
      [],
      toolkit
    );
  }
);

export const getGstVerifiedApiAsync = createAsyncThunk(
  "lead/getLeadCheckParameters",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/gst/verify-gst-number/${payload?.gstNumber}`,
      [],
      toolkit
    );
  }
);


export const getLeadListBusinessNameAsync = createAsyncThunk(
  "contract/getLeadListBusinessName",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/lead/list-business-name`,
      [],
      toolkit
    );
  }
);