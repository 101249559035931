import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

export const getAllCertificateAsync = createAsyncThunk(
  "service/getAllCertificate",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/certificate/list-certificate?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&vesselName=${payload?.vesselName || ""}&containerNo=${
        payload?.containerNo || ""
      }&client=${payload?.client || ""}&job=${payload?.job || ""}&search=${
        payload?.search
      }&sDate=${payload?.startDate}&eDate=${payload?.endDate}&certificateNumber=${payload?.certificateNumber || ""}`,
      [],
      toolkit
    );
  }
);

export const getJobDropdownByCustomerIdAsync = createAsyncThunk(
  "certificate/getJobDropdownByCustomerId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/certificate/get-job-dropdown/${payload}`,
      [],
      toolkit
    );
  }
);

// get-certificates-ofJob
export const getCertificatesOfJobAsync = createAsyncThunk(
  "certificate/getCertificatesOfJob",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/service/certificate/get-certificates-ofJob/${payload?.customerId}`,
      payload,
      toolkit
    );
  }
);

// Create Certificate

export const createCertificateAsync = createAsyncThunk(
  "createCertificate",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/service/certificate/create-certificate`,
      payload,
      toolkit
    );
  }
);

// required later
// export const getCertificateDocumentDropdownAsync = createAsyncThunk(
//   "certificate/getCertificateDocumentDropdown",
//   async (payload, toolkit) => {
//     return await AxiosClient(
//       "GET",
//       `/service/certificate/list-document-dropdown/${payload}`,
//       [],
//       toolkit
//     );
//   }
// );
export const getCertificateDocumentDropdownAsync = createAsyncThunk(
  "certificate/getCertificateDocumentDropdown",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/certificate/list-document-dropdown?certificateType=${payload?.certificateType}`,
      [],
      toolkit
    );
  }
);

//getByID Certificate
export const getCertificateByIdAsync = createAsyncThunk(
  "service/getCertificateById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/certificate/get-single-certificate/${payload}`,
      [],
      toolkit
    );
  }
);

// get-document-html
export const getCertificateDocumentHtmlByDocumentIdAsync = createAsyncThunk(
  "certificate/getCertificateDocumentHtmlByDocumentId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/certificate/get-document-html/${payload?.documentId}/${payload?.certificateId}`,
      [],
      toolkit
    );
  }
);

// Create Certificate Check Box
export const createCertificateCheckBoxAsync = createAsyncThunk(
  "createCertificateCheckBox",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/service/certificate/create-certificate-checkbox`,
      payload,
      toolkit
    );
  }
);

//  Certificate Send Email
export const certificateEmailPdfAsync = createAsyncThunk(
  "certificateEmailPdf",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/service/certificate/save-pdf-and-mail/${payload.id}`,
      payload,
      toolkit
    );
  }
);

export const certificateExcelDownloadAsync = ({
  page,
  limit,
  vesselName,
  containerNo,
  client,
  job,
  search,
}) => {
  window.open(
    `${
      process.env.REACT_APP_BASE_URL
    }/service/certificate/certificates-export-to-csv?token=${
      isJson(localStorage.getItem("auth"))
        ? JSON.parse(localStorage.getItem("auth"))?.token
        : null
    }&page=${page || 1}&limit=${limit || 10}&vesselName=${
      vesselName || ""
    }&containerNo=${containerNo || ""}&client=${client || ""}&job=${
      job || ""
    }&search=${search}`,
    "_parent"
  );
};
